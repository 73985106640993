import { useEffect, useState } from "react";
import "./scoreBoard.css";
import { collection } from "../firebase/firebaseconfig";

const ScoreBoard = (props) => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    collection.doc("teams").onSnapshot((doc) => {
      let data = doc.data();
      setTeams(data);
    });
  }, []);

  const getTeamScore = (team) => {
    let score = 0;
    if (team) {
      console.log(team);
      if (team["g1"] && team["g1"].length > 0) {
        let g1 = team["g1"].at(-1).score / 100;
        let wrongEntries = 6 - g1;
        let tmp = g1 * 2 - wrongEntries;
        score += tmp > 0 ? tmp : 0;
      }
      if (team["g2"] && team["g2"].length > 0) {
        let g2 = team["g2"].at(-1).score / 100;
        let wrongEntries = 4 - g2;
        let tmp = g2 * 2 - wrongEntries;
        score += tmp > 0 ? tmp : 0;
      }
      if (team["g3"] && team["g3"].length > 0) {
        let g3 = team["g3"].at(-1).score / 100;
        let wrongEntries = 15 - g3;
        let tmp = g3 * 2 - wrongEntries;
        score += tmp > 0 ? tmp : 0;
      }
      if (team["g4"] && team["g4"].length > 0) {
        let g4 = team["g4"].at(-1).score / 100;
        let wrongEntries = 5 - g4;
        let tmp = g4 * 2 - wrongEntries;
        score += tmp > 0 ? tmp : 0;
      }
    }
    return score;
  };

  return (
    <div className="selectTeamContainer">
      <div className="rowContainer">
        <div className="team">
          <div className="team1" />
          {getTeamScore(teams["t1"])} POINTS
        </div>
        <div className="team">
          <div className="team2" />
          {getTeamScore(teams["t2"])} POINTS
        </div>
      </div>

      <div className="rowContainer">
        <div className="team">
          <div className="team3" />
          {getTeamScore(teams["t3"])} POINTS
        </div>
        <div className="team">
          <div className="team4" />
          {getTeamScore(teams["t4"])} POINTS
        </div>
      </div>

      <div className="rowContainer">
        <div className="team">
          <div className="team5" />
          {getTeamScore(teams["t5"])} POINTS
        </div>
      </div>
    </div>
  );
};
export default ScoreBoard;
